@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700&display=swap);
/* html {
	height: 100%;
} */
body {
	/* height: 100%; */
	margin: 0;
	padding: 0;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* #root {
	height: 100%;
} */
* {
	margin: 0;
	padding: 0;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

